/* eslint-disable no-unused-vars */
import { put, delay, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	LOGIN_API,
	SOCIAL_LOGIN,
	RESEND_EMAIL_VERIFICATION_API,
	VERIFY_MFA_API,
	SWITCH_VERIFICATION_METHOD_API,
	SWITCH_VERIFICATION_EMAIL_API,
} from "../actions/apiCollections";
import {
	logoutSucceed,
	logout,
	authStart,
	authSuccess,
	adminLoginSuccess,
	authFail,
	checkAuthTimeout,
	setAlert,
	resendEmailVerificationSuccess,
	resendEmailVerificationFail,
	twoFactorAuthSuccess,
	isSpinning,
	verifyMFAError,
	getMFAAuthSuccess,
} from "../actions/index";
import { getCurrentUserSaga } from "./getCurrentUserSaga";
import { getCurrentUserSuccess } from "../actions/index";
import {
	getCampaignSettingsSaga,
	getSiteSettingsSaga,
} from "./siteSettingsSaga";
import { getLanguageCode } from "../../helpers/helpers";

export function* logoutSaga(action) {
	yield put(authStart());
	yield localStorage.removeItem("token");
	yield localStorage.removeItem("expirationDate");
	yield localStorage.removeItem("userType");
	yield localStorage.removeItem("email");
	yield localStorage.removeItem("slug");
	yield localStorage.setItem("persist:root", {});
	yield put(logoutSucceed());
	yield put(getCurrentUserSuccess({}));
}

export function* checkAuthTimeoutSaga(action) {
	yield delay(action.expirationTime * 6000);
	yield put(logout());
	yield call(logoutSaga);
}

export function* authLoginSaga(action) {
	yield put(authStart());
	yield put(isSpinning(true));

	const authData = {
		email: action.email,
		password: action.password,
		createdAt: Date.now(),
	};
	try {
		const response = yield axios.post(`${LOGIN_API}`, authData, {
			params: {
				...(getLanguageCode() !== null && {
					lng: getLanguageCode(),
				}),
				active: 1,
			},
		});

		if (response.data.token) {
			const expirationTokenTime = 10800;
			const expirationDate = yield new Date(
				new Date().getTime() + expirationTokenTime * 1000
			);
			yield localStorage.setItem("token", response.data.token);
			yield localStorage.setItem("expirationDate", expirationDate);
			yield localStorage.setItem("email", action.email);
			yield localStorage.setItem("slug", response.data.data.user.slug);
			yield localStorage.setItem("userType", response.data.data.user.userType);
			yield localStorage.setItem(
				"twoFactorLoginStatus",
				response.data.message.enableTwoFactorAuth
			);
			yield call(getCurrentUserSaga);
			yield call(getCampaignSettingsSaga);
			yield call(getSiteSettingsSaga);
			yield put(authSuccess(response.data.token));
			yield put(checkAuthTimeout(expirationTokenTime));
			yield delay(500);
			yield put(setAlert(true));
			yield delay(8000);
			yield put(setAlert(false));
			yield put(isSpinning(false));
		} else {
			if (response.data.data.data.otpPreferenceTool === "EMAIL") {
				yield put(getMFAAuthSuccess(response.data.data.data));
				yield put(isSpinning(false));
			} else if (response.data.data.data.otpPreferenceTool === "2FA") {
				if (response.data.data.data.twoFactorAuthVerified === "no") {
					yield put(getMFAAuthSuccess(response.data.data.data));
					yield put(isSpinning(false));
				} else if (response.data.data.data.twoFactorAuthVerified === "yes") {
					yield put(getMFAAuthSuccess(response.data.data.data));
					yield put(isSpinning(false));
				}
			}
		}
	} catch (error) {
		yield put(isSpinning(false));
		yield put(authFail(error.response.data.message));
	}
}

export function* adminLoginSaga(action) {
	try {
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);
		yield localStorage.setItem("token", action.credentials.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", action.credentials.email);
		yield localStorage.setItem("id", action.credentials.id);
		yield localStorage.setItem("slug", action.credentials.slug);
		yield localStorage.setItem("userType", action.credentials.userType);
		yield call(getCurrentUserSaga);
		yield put(authSuccess(action.credentials.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield put(adminLoginSuccess(action.credentials));
	} catch (error) {
		yield put(authFail(error.response.data.message));
	}
}

export function* auth0LoginSaga(action) {
	yield put(authStart());
	try {
		const response = yield axios.post(`${SOCIAL_LOGIN}`, action.data, {
			params: {
				...(getLanguageCode() !== null && {
					lng: getLanguageCode(),
				}),
			},
		});
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);
		yield localStorage.setItem("token", response.data.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", response.data.data.user.email);
		yield localStorage.setItem("slug", response.data.data.user.slug);
		yield localStorage.setItem("userType", response.data.data.user.userType);
		yield put(authSuccess(response.data.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield call(getCurrentUserSaga);
		yield call(getCampaignSettingsSaga);
		yield call(getSiteSettingsSaga);

		yield delay(500);
		yield put(setAlert(true));
		yield delay(8000);
		yield put(setAlert(false));
	} catch (error) {
		yield put(authFail(error.response.data.message));
	}
}

export function* authCheckStateSaga(action) {
	yield put(authStart());
	const token = yield localStorage.getItem("token");
	if (!token) {
		yield call(logoutSaga);
	} else {
		const expirationDate = yield new Date(
			localStorage.getItem("expirationDate")
		);
		if (expirationDate <= new Date()) {
			yield call(logoutSaga);
		} else {
			yield put(authSuccess(token));
			yield put(
				checkAuthTimeout(
					(expirationDate.getTime() - new Date().getTime()) / 1000
				)
			);
		}
	}
}

export function* resendEmailVerificationSaga(action) {
	yield put(authStart());
	try {
		const response = yield axios.post(
			`${RESEND_EMAIL_VERIFICATION_API}`,
			{
				email: action.email,
			},
			{
				params: {
					...(getLanguageCode() !== null && {
						lng: getLanguageCode(),
					}),
				},
			}
		);
		yield put(resendEmailVerificationSuccess(response.data.data.data));
	} catch (error) {
		yield put(resendEmailVerificationFail(error.response.data.message));
	}
}

export function* verifyMFASaga(action) {
	yield put(authStart());
	yield put(isSpinning(true));

	const authData = {
		email: action.email,
		otpPreferenceTool: action.otpPreferenceTool,
		code: action.mfaCode,
	};
	try {
		const response = yield axios.post(`${VERIFY_MFA_API}`, authData, {
			params: {
				...(getLanguageCode() !== null && {
					lng: getLanguageCode(),
				}),
			},
		});
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);
		yield localStorage.setItem("token", response.data.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", action.email);
		yield localStorage.setItem("slug", response.data.data.user.slug);
		yield localStorage.setItem("userType", response.data.data.user.userType);
		yield localStorage.setItem(
			"twoFactorLoginStatus",
			response.data.message.enableTwoFactorAuth
		);
		yield call(getCurrentUserSaga);
		yield call(getCampaignSettingsSaga);
		yield call(getSiteSettingsSaga);
		yield put(authSuccess(response.data.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield delay(500);
		yield put(setAlert(true));
		yield delay(8000);
		yield put(setAlert(false));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(isSpinning(false));
		yield put(verifyMFAError(error.response.data.message));
	}
}

export function* switchVerificationMethodSaga(action) {
	yield put(authStart());
	yield put(isSpinning(true));

	const authData = {
		email: action.email,
	};
	try {
		const response = yield axios.patch(
			`${
				action.switchOption === "EMAIL"
					? SWITCH_VERIFICATION_METHOD_API
					: SWITCH_VERIFICATION_EMAIL_API
			}`,
			authData,
			{
				params: {
					...(getLanguageCode() !== null && {
						lng: getLanguageCode(),
					}),
				},
			}
		);

		if (response.data.data.data.otpPreferenceTool === "EMAIL") {
			yield put(getMFAAuthSuccess(response.data.data.data));
			yield put(isSpinning(false));
		} else if (response.data.data.data.otpPreferenceTool === "2FA") {
			if (response.data.data.data.twoFactorAuthVerified === "no") {
				yield put(getMFAAuthSuccess(response.data.data.data));
				yield put(isSpinning(false));
			} else if (response.data.data.data.twoFactorAuthVerified === "yes") {
				yield put(getMFAAuthSuccess(response.data.data.data));
				yield put(isSpinning(false));
			}
		}

		yield put(isSpinning(false));
	} catch (error) {
		yield put(isSpinning(false));
		yield put(authFail(error.response.data.message));
	}
}
